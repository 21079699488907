var CountryStockists = function($container) {
	this.$container = $container;
	
	this._init();
}

CountryStockists.prototype = {
	_init: function() {
		var me = this;

		if ( !me.$container.length ) {
			return;
		}
		
		me.$tabs = me.$container.find('.js-tab');
		me.$stockists = me.$container.find('.js-stockists');
		
		me._build();
	},
	
	_build: function() {
		var me = this;
		
		me.$tabs.on('click.tabs', function(e) {
			e.preventDefault();
			me._switchTabs( $(this).attr('data-for') );
		});
	},
	
	_switchTabs: function(id) {
		var me = this;
		
		if ( id !== null && typeof(id) !== 'undefined' ) {
			me.$tabs.removeClass('-selected');
			me.$tabs.filter('[data-for=' + id + ']').addClass('-selected');
			me.$stockists.removeClass('-selected');
			me.$stockists.filter('[data-id=' + id + ']').addClass('-selected');
		}
	}
}