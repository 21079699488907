$(function(){
	
	var el, 
			header,
			nav,
			settings, 
			tip;
		
	el = {
		articles: $('article'),
		body: $('body'),
		compressedHeader: $('header.compressed'),
		dictionaryWords: $('article u'),
		fullHeader: $('header.full'),
		bookHeader: $('.js-book-header'),
		note: $('.underline-note'),
		spinWords: $('a.spin'),
		frightWords: $('a.fright'),
		pressLinks: $('.press ul a'),
		springToTop: $('nav .current'),
		rssLink: $('.rss-link')
	};
	
	newsletter = {
		trigger: $('.js-email-signup-trigger'),
		focusTrigger: $('.js-email-signup-focus'),
		form: $('.email-signup-form'),
		input: $('.email-input'),
		container: $('.js-email-signup-form-container')
	}
	
	header = {
		compressed: false,
		fullHeight: el.fullHeader.outerHeight(),
		compressedHeight: el.compressedHeader.innerHeight()
	};
	
	if ( el.bookHeader.length ) {
		header.fullHeight = header.fullHeight + el.bookHeader.outerHeight();
	}
	
	nav = {
		mobileOpen: false, 
		trigger: $('.js-menuburger'),
		el: $('header nav'),
		dropdown: $('header.compressed nav .secondary'),
		topMobile: $('header.full nav .secondary')
	};
	
	settings = {
		w: window.innerWidth,
		h: window.innerHeight
	};
	
	tip = {
		visible: false
	};

	// Shows note about Dictionary Words
	function showTip() {
		el.note.addClass('showing');
		setTimeout( function(){ hideTip() }, 5000);
		tip.visible = true;
	};
	
	function hideTip() {
		if(tip.visible) {
			el.note.removeClass('showing');
			tipShowing = false;
		}
	};
	
	function compressedHeader() {
		el.compressedHeader.addClass('visible');
		header.compressed = true;
	};
	
	function uncompressedHeader() {
		el.compressedHeader.removeClass('visible');
		header.compressed = false;
	};
	
	// Scrolls page back to top
	scrollToTop = function (link) {
		link.on('click',function (e) {
			// If we are on the origin page, use current page link to jump us to top
			// rather than reload. Ignore for child pages and query parameters
			if($(this).attr('href') === location.pathname + location.search ) {
		    e.preventDefault();
		    var target = this.hash;
				var offest = el.body.scrollTop();
				var animationTime = offest / 5;
				
		    el.body.stop().animate({
		        'scrollTop': 0
		    }, animationTime, 'swing', function () {
		        window.location.hash = target;
		    });
			}
		});
	};
	
	// Toggles header state when page scrolled or at top
	toggleHeader = function () {
		if ( window.scrollY > header.fullHeight ) {
			compressedHeader();
			header.compressed = true;
		} else {
			uncompressedHeader();
			header.compressed = false;
		}
		
	};
	
	function scrollEffect(){
		stroll.bind( '.container' );
	}

	// All functions to perform on page load 
	function setup() {
		scrollToTop(el.springToTop);
		mobileNavToggle();
	}
	
	function mobileNavOpen() {
		// nav.trigger.addClass('x');
		nav.el.addClass('mobile-nav-open');
		nav.mobileOpen = true;
	}
	
	function mobileNavClose() {
		// nav.trigger.removeClass('x');
		nav.el.removeClass('mobile-nav-open');
		nav.mobileOpen = false;
	}
	
	function updateHeaderHeight() {
		header.compressedHeight = el.compressedHeader.innerHeight();
		header.fullHeight = el.fullHeader.outerHeight();
		
		if ( el.bookHeader.length ) {
			header.fullHeight = header.fullHeight + el.bookHeader.outerHeight();
		}
		
		if ( header.compressed ) {
			// nav.dropdown.css( 'top', header.compressedHeight );
			// nav.topMobile.css( 'top', '0px' );
		} else {
			// nav.topMobile.css( 'top', window.scrollY );
			// nav.topMobile.css( 'min-height', (header.fullHeight - window.scrollY) + 'px' );
		} 
	}
	
	function mobileNavToggle(){
		updateHeaderHeight();
		
		nav.trigger.on('click', function(){
			// if(nav.mobileOpen){
			// 	mobileNavClose();
			// }
			// else {
			// 	mobileNavOpen();
			// }
		});
	}
	
	function newsletterFormShow() {
		newsletter.trigger.hide();
		newsletter.form.show();
		newsletterFormFocus();
	}
	
	function newsletterFormHide() {
		newsletter.trigger.show();
		newsletter.form.hide();
	}
	
	function newsletterFormFocus() {
		newsletter.input.focus();
		newsletter.focusTrigger.replaceWith('<p class="email-hint">Look up!</p>');
	}
	
	function mobileFocus(){
		el.rssLink.hide();
	}
	
	
	function newsletterFormHide() {
		newsletter.form.hide();
		newsletter.trigger.show();
	}
	
	// Initialisation function 
	function kickIt() {
		setup();
	}
	
	var stockistTabs = new CountryStockists( $('.js-country-stockists') );
	
	el.articles.fitVids();
	
	// Binding 
	el.dictionaryWords.bind('click', showTip);
	newsletter.trigger.bind('click', newsletterFormShow);
	// newsletter.input.bind('focusout', newsletterFormHide);
	newsletter.focusTrigger.bind('click', newsletterFormFocus);
	newsletter.input.bind('focusin', mobileFocus);
	// newsletter.input.bind('focusout', mobileFocusOut);

	
	
	if (newsletter.container.length) {
		newsletter.container.append(newsletter.form);
	}
	
	// event listener
	window.addEventListener('scroll', toggleHeader, false);
	window.addEventListener('scroll', hideTip, false);
	window.addEventListener('scroll', mobileNavClose, false);
	window.addEventListener('resize', mobileNavClose, false);
	// window.addEventListener('scroll', updateHeaderHeight, false);
	window.addEventListener('resize', updateHeaderHeight, false);

	
	// initialise on document load
	window.addEventListener('load', kickIt);
	  
})


